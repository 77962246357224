<template>
  <clubVoting
    v-if="showComp"
    :clubID="detailObj.id"
    :clubDetails="detailObj"
    @back="closeComp"
  />

  <div v-else>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Club</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Cover"
              invalid-feedback="Cover is required"
              ref="cover"
            >
              <div>
                <b-spinner
                  v-if="coverLoading"
                  type="grow"
                  label="Loading..."
                  variant="primary"
                  class="m-5"
                />
                <b-img
                  v-else-if="!myObj.cover"
                  ref="previewEl"
                  src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                  @click="$refs.coverImg.click()"
                  style="border-radius: 10px"
                  height="120px"
                  width="120px"
                />
                <div v-else class="d-flex align-items-center">
                  <b-img
                    ref="previewEl"
                    :src="myObj.cover"
                    style="border-radius: 10px"
                    height="120px"
                    width="120px"
                  />

                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="text-danger cursor-pointer ml-1"
                    @click="removeCover()"
                  />
                </div>
                <input
                  type="file"
                  id="coverImg"
                  hidden
                  ref="coverImg"
                  @change="addCover()"
                  accept="image/*"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Title"
              invalid-feedback="Title is required."
              ref="title"
            >
              <b-form-input
                v-model.trim="myObj.title"
                placeholder="Enter title"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Total Seats"
              invalid-feedback="Total Seats is required."
              ref="seats"
            >
              <b-form-input
                type="number"
                v-model="myObj.seats"
                placeholder="Enter total seats"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Classes"
              invalid-feedback="Class is required."
              ref="class"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="classIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                :disabled="dataLoading"
                placeholder="Select classes"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Description" ref="desc">
              <b-form-textarea
                v-model.trim="myObj.description"
                rows="3"
                placeholder="Enter club description"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              variant="success"
              :disabled="request || coverLoading"
              @click="save()"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else class="align-middle">Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-card>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12">
          <b-button
            variant="primary"
            block
            @click="AddOpen(0)"
            :disabled="dataLoading"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Club</span>
          </b-button>
        </b-col>
        <b-col xl="10" lg="9" md="8" sm="12" class="mt-md-0 mt-1">
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        class="mt-1"
        :busy="itemLoading"
        :items="filters"
        :fields="fields"
        show-empty
        responsive
        hover
        small
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1 text-center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(cover)="data">
          <b-img
            v-if="data.item.cover"
            rounded
            :src="data.item.cover"
            height="100px"
            width="100px"
            alt=""
          />
          <b-img
            v-else
            rounded
            src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
            height="100px"
            width="100px"
            alt=""
          />
        </template>
        <template #cell(title)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(classes)="data">
          <b-badge variant="light-primary">
            {{ getClass(data.item.classes) }}
          </b-badge>
        </template>
        <template #cell(seats)="data">
          <b-badge variant="light-primary">
            {{ data.item.occupied }} / {{ data.value }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              variant="primary"
              v-b-tooltip.hover.bottomleft
              title="View Votes"
              class="btn-icon mr-1"
              @click="openComp(data.item)"
            >
              <feather-icon icon="EyeIcon" class="" />
            </b-button>

            <!-- v-if="rights.edit" -->
            <b-button
              variant="primary"
              v-b-tooltip.hover.bottomleft
              title="Edit"
              class="btn-icon mr-1"
              @click="AddOpen(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <!-- v-if="rights.delete" -->
            <b-button
              variant="outline-danger"
              v-b-tooltip.hover.bottomright
              title="Delete"
              class="btn-icon"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BSidebar,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";
import clubVoting from "./clubVoting.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSidebar,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    vSelect,
    flatPickr,
    clubVoting,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async created() {
    this.LoadClasses();
    this.LoadData();
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.title.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.description.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      rights: {},
      config: {
        enableTime: true,
        altFormat: "j M, Y h:i K",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
      },
      searchQuery: "",
      dataLoading: false,
      itemLoading: false,
      items: [],
      fields: [
        { label: "cover", key: "cover" },
        { label: "title", key: "title" },
        { label: "classes", key: "classes" },
        { label: "seats", key: "seats" },
        // { label: "description", key: "description" },
        "actions",
      ],
      visibility: false,
      myObj: {
        id: 0,
        cover: "",
        title: "",
        classes: "",
        seats: 0,
        occupied: 0,
        description: "",
        status: "active",
        campusID: this.$store.state.userData.cId,
      },
      currentItem: {},
      showLink: false,
      linkLoading: false,
      request: false,
      classes: [],
      classesOptions: [],
      classIDs: [],
      coverLoading: false,
      showComp: false,
      detailObj: {},
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openComp(item) {
      this.showComp = true;
      this.detailObj = item;
      this.detailObj.classNames = this.getClass(this.detailObj.classes);
    },
    closeComp() {
      this.showComp = false;
      this.detailObj = {};
    },
    getClass(cID) {
      let ids = cID
        .split(",")
        .filter((el) => el)
        .map((el) => parseInt(el));
      let names = [];
      ids.forEach((el) => {
        let obj = this.classesOptions.find((elem) => elem.id == el);
        if (obj) names.push(obj.name);
      });
      return names.join(", ");
    },

    addCover() {
      let media = this.$refs.coverImg.files[0];
      if (media) {
        this.coverLoading = true;
        let formData = new FormData();
        formData.append("file", media);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.myObj.cover = resp;
            this.coverLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.coverLoading = false;
          })
          .finally(() => (this.coverLoading = false));
      }
    },
    removeCover() {
      this.myObj.cover = "";
      this.$refs.coverImg.files = null;
    },

    AddOpen(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          cover: "",
          title: "",
          classes: "",
          seats: 0,
          occupied: 0,
          description: "",
          status: "active",
          campusID: this.$store.state.userData.cId,
        };
        this.classIDs = [];
      } else {
        this.myObj = { ...id };
        this.classIDs = this.myObj.classes
          .split(",")
          .filter((el) => el)
          .map((el) => parseInt(el));
      }
      this.visibility = true;

      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["seats"];
      elem.state = undefined;
      var elem = this.$refs["class"];
      elem.state = undefined;
    },

    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSeats() {
      var elem = this.$refs["seats"];
      let x = parseInt(this.myObj.seats);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClass() {
      var elem = this.$refs["class"];
      if (this.classIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDesc() {
      var elem = this.$refs["desc"];
      if (this.myObj.description == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      this.CheckTitle();
      this.CheckSeats();
      this.CheckClass();
      // this.CheckDesc();
      if (
        this.CheckTitle() == false ||
        this.CheckSeats() == false ||
        this.CheckClass() == false
        // ||  this.CheckDesc() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.myObj.seats = parseInt(this.myObj.seats);
        this.myObj.classes = this.classIDs.join(",");
        // console.log(this.myObj);

        if (this.myObj.id == 0) {
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Clubs?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Club added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;

          if (status) {
            this.LoadData();
            this.visibility = false;
          }
        } else {
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Clubs/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Club updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadData();
            this.visibility = false;
          }
        }
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Clubs/" +
            id +
            "?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId,
          message: "Club deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },

    async LoadData() {
      this.itemLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Clubs?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.itemLoading = false;
    },
    async LoadClasses() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      this.dataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
